import React from 'react';
import { NextSeo } from 'next-seo';
// import Head from 'next/head';
// import classnames from 'classnames';

const CustomHead = (props) => {
  const {
    title = '護膚品好用推介/比較 | 化妝品推薦香港 | 最真實化妝護膚品評價 - Sorra',
    desc = '尋找護膚品好用的推薦和比較？Sorra提供最詳實的產品評論、價格比較、成份分析和用戶意見。我們根據用戶評價和專業評測，比較各種化妝品和護膚品，幫助您做出明智的選擇。無論您需要什麼，從化妝品到護膚品，我們的平台都有您需要的資訊。立即探索，找到最適合您的產品！',
  } = props;

  return (
    <>
      <NextSeo title={title} description={desc} />

      {/* <Head> */}
      {/* <title>{title}</title> */}
      {/* <meta name="description" content={desc} /> */}
      {/* <link rel="icon" href="https://sorra.azureedge.net/assets/favicon.ico" /> */}
      {/* <meta property="og:site_name" content="Sorra" />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={desc} />
        <meta property="og:image" content="/snsThumbnail.jpg" />
        <meta property="og:type" content="website" /> */}
      {/* <link rel="apple-touch-icon" href="/images/appleTouchIcon.png" /> */}
      {/* <meta name="apple-mobile-web-app-title" content="Sorra"></meta> */}
      {/* <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1, viewport-fit=cover"
        /> */}
      {/* <meta name="twitter:image" content="/snsThumbnail.jpg" /> */}
      {/* </Head> */}

      {/* <script async src="https://www.googletagmanager.com/gtag/js?id=G-WM58T2D95H"></script>
            <script>
                window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments)}
                gtag('js', new Date());`

                `gtag('config', 'G-WM58T2D95H');
            </script> */}
    </>
  );
};

export default CustomHead;
